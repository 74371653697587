import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog31 = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(
        relativePath: { eq: "videos/poster-katalog-feiertage-2.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="Hanns Zischler: Wie wenn am Feiertage" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Hanns Zischler: Wie wenn am Feiertage",
                link: "/k/31",
              },
            ]}
          />
          <PageTitle>Hanns Zischler: Wie wenn am Feiertage</PageTitle>
          <VideoPlayer src="katalog/31-hanns-zischler" poster={data.poster} />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog31
